<template>
  <div class="edit-view">
    <page-title>
      <el-button @click="$router.go(-1)"
                 :disabled="saving">取消</el-button>
      <el-button @click="onDelete"
                 :disabled="saving"
                 type="danger"
                 v-if="formData.id"> 删除 </el-button>
      <el-button @click="onSave"
                 :loading="saving"
                 type="primary">保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form :model="formData"
                 :rules="rules"
                 ref="form"
                 label-width="94px"
                 label-position="right"
                 size="small"
                 style="max-width: 500px">
          <el-form-item label="项目"
                        prop="projectId">
            <project-select v-model="formData.projectId">
            </project-select>
          </el-form-item>
          <el-form-item prop="createdAt"
                        label="时间选择">
            <el-date-picker v-model="formData.createdAt"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="surpriseBoxId"
                        label="惊喜盒子">
            <el-select v-model="formData.surpriseBoxId"
                       filterable>
              <el-option v-for="item in boxList"
                         :label="item.name"
                         :value="item.id"
                         :key="item.id">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">#{{ item.id }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form-submit">
            <el-button @click="onSave"
                       :loading="saving"
                       type="primary"> 保存 </el-button>
            <el-button @click="onDelete"
                       :disabled="saving"
                       type="danger"
                       v-if="formData.id">
              删除
            </el-button>
            <el-button @click="$router.go(-1)"
                       :disabled="saving">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'AirDropTime',
    created() {
        this.getBoxList();
    },
    data() {
        return {
            saving: false,
            boxList: [],
            formData: {
                projectId: this.$store.state.projectId
            },
            rules: {
                projectId: [{ required: true, message: '请选择项目' }],
                createdAt: [{ required: true, message: '请选择时间' }],
                surpriseBoxId: [{ required: true, message: '请选择惊喜盒子' }]
            }
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };
            this.saving = true;
            this.$http
                .post('/airDrop/sendBox', data)
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        getBoxList() {
            // this.saving = true;
            const data = {
                page: 0,
                query: {
                    del: false,
                    projectId: this.formData.projectId,
                    createdAt: ''
                },
                size: 10000,
                sort: 'createdAt,desc'
            };
            this.$http
                .post('/activitySurpriseBox/all', data, { body: 'json' })
                .then(res => {
                    this.boxList = res.content;
                    // this.saving = false;
                })
                .catch(e => {
                    // this.saving = false;
                    this.$message.error(e.error);
                });
        }
    },
    watch: {
        'formData.projectId'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.getBoxList();
            }
        }
    }
};
</script>
<style lang="less" scoped></style>
